<template>
  <div class="hello">
    <img src="@/assets/imgHome1.jpg" class="full-screen-image"/>

    <!-- Free Posting -->
    <div
        class="clickable-area free-posting"
        @click="handleClick('imgFreePost')"
    ></div>

    <!-- Trusted Vendors -->
    <div
        class="clickable-area trusted-vendors"
        @click="handleClick('imgTrustVendor')"
    ></div>

    <!-- Simplified Delivery -->
    <div
        class="clickable-area simplified-delivery"
        @click="handleClick('imgShipping')"
    ></div>

    <!-- Find Oversea Supplier -->
    <div
        class="clickable-area find-oversea"
        @click="handleClick('imgFindSuppliers')"
    ></div>

    <!-- WhatsApp -->
    <div
        class="clickable-area whatsapp"
        @click="openWhatsAppChat"
    ></div>

    <!-- 模式窗口 -->
    <div v-if="modalVisible" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <img :src="imageUrl" alt="modal-image" class="modal-image"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      modalVisible: false, // 控制模式窗口是否显示
      imageUrl: '', // 图片路径
    };
  },
  methods: {
    handleClick(area) {
      this.openModal(area);
    },
    openModal(area) {
      this.imageUrl = require(`@/assets/${area}.jpg`);
      console.log(this.imageUrl); // 检查图片路径
      this.modalVisible = true; // 显示模式窗口
    },
    closeModal() {
      this.modalVisible = false; // 关闭模式窗口
    },
    openWhatsAppChat() {
      const phoneNumber = '330602564326'; // 替换为目标WhatsApp账号的号码，去掉+号和空格
      const message = 'Hello, I would like to chat with you about ETBuy!'; // 预填的消息
      const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
      window.open(whatsappUrl, '_blank'); // 打开新的浏览器标签页
    },
  },
};
</script>

<style scoped>
/* 全屏背景图片 */
.full-screen-image {
  width: 100%;  /* 让图片宽度填充父容器 */
  height: 100%;  /* 让图片高度也填充父容器 */
  object-fit: contain; /* 保持比例显示，且不裁剪 */
  display: block; /* 防止间隙 */
  position: absolute; /* 固定位置 */
  top: 0; /* 贴近顶部 */
  left: 50%; /* 水平居中 */
  transform: translateX(-50%); /* 水平偏移修正 */
  z-index: -1; /* 位于底层 */
}

/* 通用点击区域样式 */
.clickable-area {
  position: absolute;
  cursor: pointer;
}

/* Free Posting 区域 */
.free-posting {
  top: 53.5%;
  left: 22.5%;
  width: 22.5%;
  height: 10%;
}

/* Trusted Vendors 区域 */
.trusted-vendors {
  top: 53%;
  left: 58.5%;
  width: 23%;
  height: 10%;
}

/* Simplified Delivery 区域 */
.simplified-delivery {
  top: 67%;
  left: 22.5%;
  width: 22.5%;
  height: 10%;
}

/* Find Oversea Supplier 区域 */
.find-oversea {
  top: 65.1%;
  left: 58.5%;
  width: 23%;
  height: 10%;
}

/* WhatsApp 区域 */
.whatsapp {
  top: 80%;
  left: 93.1%;
  width: 4%;
  height: 8%;
}

/* 模式窗口样式 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* 确保在最上层 */
}

.modal-content {
  margin-top: 20vh; /* 向下偏移 20vh */
  border-radius: 0px;
  width: 70vw; /* 使模式窗口宽度自适应 */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); /* 添加阴影效果 */
}

.modal-image {
  width: 100%;
  height: auto; /* 保持图片比例 */
}
</style>
